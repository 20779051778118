/* ========================================================================
     Component: calendar
 ========================================================================== */

.calendar-app {

    .fc-button {
        @extend .btn-sm;
        @extend .btn-secondary;
        height: auto;
        background-image: none;
        padding: 5px 10px;
        &:active,
        &.active,
        &.fc-state-active {
            outline: 0;
            background-image: none;
            @include box-shadow(inset 0 3px 5px rgba(0, 0, 0, .125));
        }
    }

    .fc-button .fc-icon {
        margin: 0 .1em;
        font-size: inherit;
        font-family: "Font Awesome 5 Free" !important;
        vertical-align: baseline;
        color: #555;
        line-height: 1.5; // Remove any icon predefined by FullCalendar
        &:after {
            display: none;
        }
    }

    .fc-state-highlight,
    .fc-day:hover {
        background-color: #f7f8f9;
    }

    .fc-day-number {
        font-size: 18px;
        font-weight: bold;
        color: #a1a2a3;
    }

    .fc-event {
        color: #fff !important;
    } // Allow to drag event outside the calendar
    .fc-view {
        overflow: visible;
    } // Calendar app styles
    // -----------------------
    .external-events {
        margin: 0;
        >div {
            // display: inline-block;
            margin-right: 5px;
            margin-bottom: 5px;
            padding: 6px 10px;
            color: #fff;
            font-size: 11px;
            border-radius: 2px;
            cursor: move;
        }
        &:empty {
            content: "EMRTPUY";
        }
    } // Remove events drop area

    .external-event-color-selector {
        .circle.selected {
            border: 3px solid rgba(255, 255, 255, .5);
        }
    }
}
